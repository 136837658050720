  .signup-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f3f4f2;
  }

  .health-eagle-ls {
    width: 60vw;
    height: 100vh;
    background-color: #ffffff;
  }

  .health-eagle-text {
    color: #14D77C;
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 49.98px;
    word-wrap: break-word;
    text-align: center;
    padding-top: 30px;
  }

  .first-step {
    color: black;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    line-height: 70px;
    margin-top: 100px;
    padding-left: 70px;
    word-wrap: break-word;
    padding: 40px;
    text-align: start;
  }
  
  .signup-form{
    width: 40vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #14D77C;
  }


  .form-background{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .form-title {
    width: 350px;
    display: flex;
    text-align: center;
    color: white;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 48.84px;
    word-wrap: break-word;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center
  }

  .form-title h1{
    margin-top: 20px;
  }

  .user-form-input{
    width: 300px;
    height: 44px;
    font-size: 22px;
    text-align: left;
    padding-left: 5px;
    border-radius: 15px;
    border: 1px black solid;
  }

  .user-form-input::placeholder{
    color: rgba(0, 0, 0, 0.35);
    text-align: start;
    font-size: medium;
  }


  input[type="text"] {
    padding-left: 10px;
  }
  


  .register-button {
    width: 120px;
    height: 50px;
    text-align: center;
    background: white;
    color: #14D77C;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 32.56px;
    word-wrap: break-word;
    border-radius: 15px;
  }
  
  .login-link {
    width: 241px;
    height: 29px;
    text-align: center;
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 36.54px;
    text-decoration: underline;
    word-wrap: break-word;
    margin-top: 10px;
}
  
  
  
  
  
  
  /* For screens smaller than 600px, make the font size smaller */
  @media (max-width: 600px) {
    .header {
      font-size: 18px;
    }
  }
  
  /* For screens larger than 600px, make the font size larger */
  @media (min-width: 601px) {
    .header {
      font-size: 24px;
    }
  }


  /* For screens smaller than 600px, center the text and make the font size smaller */
@media (max-width: 600px) {
  .signup-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: auto;
    height: 100vh;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;

  }

  .health-eagle-ls {
    width: 100vw;
    height: 20vh;
    background-color: transparent;
  }

  .health-eagle-text {
    font-size: 40px;
  
  }

  .first-step {
    font-size: 20px;
    max-width: 100vw;
    width: auto;
    height: 50px;
    margin-top: 0px;
    padding: 0px;
    text-align: center;
    display: none;
  }

  .signup-form {
    background-color: transparent;
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    position: inherit;
    justify-content: flex-start;

  }

  .form-background{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: #ffffff;
    max-width: 500px;
    width: 350px;
    border-radius: 15px;

  }

  .form-title1 {
    width: 100vw;
    height: 50vh;
    color: #14D77C;

  }

  .form-title h1{
    margin-top: 10px;
    font-size: 40px;
  }

  .user-form-input{
    width: 70vw;
  }

.register-button {
    width: 40vw;
    color: #ffffff;
    background-color: #14D77C;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .login-link {
    width: 80vw;
    color: #14D77C;

  }

  .otpless-login-page{
    width: 90%;
  }


}