#root{
  background-color: #F3f4f2;
}

:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-mini: 15px;

  /* Colors */
  --color-white: #fff;
  --color-mediumspringgreen: #14d77c;
  --color-black: #000;
  --color-gray: rgba(0, 0, 0, 0.35);
  --color-gainsboro: #d9d9d9;

  /* Border radiuses */
  --br-mini: 15px;
}

.android-large-7-child {
  position: absolute;
  top: 209px;
  left: 18px;
  border: #14d77c 3px solid;
  border-radius: var(--br-mini);
  background-color:#ffffff;
  width: 323px;
  height: 382px;
}
.hospilogin {
  position: absolute;
  top: 509px;
  left: 119px;
  font-size: 20px;
  line-height: 162.8%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  height: 46px;
  background-color: #14d77c;
  color: #ffffff;
  z-index: 1;
  border: none;
}
.android-large-7-inner,
.android-large-7-item {
  position: absolute;
  top: 357px;
  left: 45px;
  border-radius: var(--br-mini);
  background-color: #ffffff;
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 259px;
  height: 44px;
}
.android-large-7-inner {
  top: 426px;
}
.enter-hospital-code,
.password {
  position: absolute;
  top: 357px;
  left: 45px;
  border-radius: var(--br-mini);
  background-color: #ffffff;
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 259px;
  height: 44px;
}
.password {
  top: 426px;


}


.span {
  color: var(--color-black);
}
.health-eagle-txt-container {
  width: 100%;
}
.for-medical-staff,
.health-eagle-2{
  position: absolute;
  left: 52px;
  display: flex;
  align-items: center;
}
.health-eagle-2 {
  top: -88px;
  font-size: 35px;
  line-height: 142.8%;
  text-align: left;
  width: 492px;
  height: 288px;
}
.for-medical-staff {
  top: 288px;
  font-size: var(--font-size-base);
  line-height: 162.8%;
  color: #14d77c;
  justify-content: center;
  width: 255px;
  height: 29px;
}
.where-care-meets {
  margin-block-start: 0;
  margin-block-end: 1px;
}
.log-in-to {
  margin: 0;
}
.where-care-meets-container {
  position: absolute;
  top: 95px;
  left: 9px;
  font-size: var(--font-size-base);
  line-height: 162.8%;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 342px;
  height: 89px;
}
.android-large-7 {
      /* width: 100%; */
      position: relative;
      background-color: #F3f4f2;
      height: 800px;
      overflow: hidden;
      text-align: center;
      font-size: var(--font-size-mini);
      color: var(--color-mediumspringgreen);
      font-family: var(--font-poppins);

}

.Login-for-doctors{
  position: relative;
  bottom: 0px;
  right: 1px;
  width: 100px;
  left: 130px;
  top: 243px;
  margin: 0px;
}


@media (max-width:600px){
  .android-large-7 {
    width: 100%;
    position: relative;
    background-color: #F3f4f2;
    text-align: center;
    font-size: var(--font-size-mini);
    color: var(--color-mediumspringgreen);
    font-family: var(--font-poppins);
    left: 0px;
    right: 0px;
  }

  .Login-for-doctors{
    position: relative;
    top: 219px;
    bottom: 0px;
  }
}

