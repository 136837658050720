.greet-user{
    max-width: 100vw;
    width: auto;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 600px){
    .greet-user{
        width: 100vw;
        height: 80vh;
        display: flex;
        margin-top: 120px;
        flex-wrap: wrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px
    }

    .greet-user h1{
        font-size: 20px;
    }
}