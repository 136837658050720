body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  .register-header{
    text-align: center;
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .register-header h5{
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0px;
  }
  
  .hospital-form {
    display: flex;
    width: 250px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
    margin-top: 10px;
  }
  
  .form-label {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  
  .doctor-info {
    margin-bottom: 20px;
  }
  
  .add-doctor-button {
    background-color: #14d77c;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .add-doctor-button:hover {
    background-color: #12c26d;
  }
  
  .submit-button {
    background-color: #14d77c;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #12c26d;
  }

.remove-doctor-button{
  position: relative;
  top: -225px;
  left: 100px;
}