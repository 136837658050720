.form1 {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between .form11 elements */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    max-width: 400px; /* Limit the .form11 width */
    margin: auto; /* Center the .form11 */
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .form1 input[type="text"] {
    width: 200px;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form1 input[type="checkbox"] {
    margin: 10px 0;
    transform: scale(1.2); /* Make the checkbox larger */
  }
  
  .form1 button {
    background-color: #14d77c;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .form1 button:hover {
    background-color: #14d77c;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #14d77c;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .switch-label {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
    vertical-align: middle;
  }
  
  .switch-label.on {
    color: #14d77c;
  }
  
  .switch-label.off {
    color: red;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 600px) {
    .form1 {
      padding: 15px;
    }
  
    .form1 input[type="text"] {
      font-size: 14px;
      padding: 8px;
    }
  
    .form1 button {
      font-size: 14px;
      padding: 8px;
    }
  }