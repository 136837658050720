
.m-div{
  margin-top: 100px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* border-style: solid; */
  margin: 6px;

  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f00;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #8fff23;;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }



  .m-div{
    /* border-style: solid; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .hos-details-banner{
    /* border-style:solid; */
    text-align: center;
    font-weight: 700;
  }

.di-2{
  /* border-style: solid; */
  display: flex;
  gap: 50px;

}


.each-d{
  /* border-style: solid; */
    flex-direction: column;
    flex-wrap: wrap;
    padding: 5px;
    margin: 4px;
    display: flex;
}


.dname-togg{
  border-style: solid;
    display: flex;
    border-color: #000;
    padding: 7px;
    font-weight: 600;
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: 0.50px black solid;
    flex-direction: column;
}

.dname-dspec{
  display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;
}

.dname-dspec-col{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  color: black;
  font-family: sans-serif;
}

.doctor-name {
  margin: 0;
}

.doctor-speciality {
  margin-top: 10px; /* Add some space above the speciality */
}


.two-btn{
  /* border-style: solid; */
  display: flex;
}

.navig{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

/* Style for the line break */
.br-line {
  display: block;
  width: 100%;
  border-top: 1px solid #111;
  margin-bottom: 20px;
  position: relative;
}

.br-line::after {
  content: '';
  display: block;
  width: 100%;
  border-top: 1px solid #ccc;
  position: absolute;
  top: 50%;
  left: 0;
}

.logout-button-2 {
  margin: 20px 0;
  text-align: center;
}

.logout-button-2 button {
  background-color: white;
  color: #ff4d4d;
  border: 2px solid #ff4d4d;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.logout-button-2 button:hover {
  background-color: #ff1a1a;
  color: white;
}

.change-password {
  margin: 20px 0;
  text-align: center;
}

.change-password button {
  background-color: white;
  color: #14d77c;
  border: 2px solid #14d77c;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.change-password button:hover {
  background-color: #14d77c;
  color: white;
}

.change-password form {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.change-password input[type="password"] {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.change-password form button {
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.change-password form button:hover {
  background-color: #007bb5;
}

/* Add this CSS to your stylesheet */
.spinner {
  border: 8px solid #14d77c; /* Light grey */
  border-top: 8px solid white; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add this CSS to your stylesheet */
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

@media screen and (max-width: 600px) {
  .di-2 {
    /* border-style: solid; */
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 100px;
    gap: 20px;
}

.slot-list {
  display: flex;
  /* border-style: solid; */
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
}
.two-btn {
  /* border-style: solid; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.hos-details-banner {
  /*border-style: solid; */
  text-align: center;
  font-weight: 600;
  font-size: larger;
  margin: 20px;
  font-size: large;
  /* border-color: #14d77c;*/
}

.m-div{
      /* border-style: solid; */
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      background: #ffffff;
      width: 320px;
      padding: 10px;
      border: #111111 0.1px solid;
      border-radius: 20px;
      margin-bottom: 25px;
}

.two-btn {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons as needed */
}

.two-btn button {
  background-color: white;
  color: #000;
  border: 2px solid #14d77c;
  border-radius: 8px; /* Adjust the radius for more or less curve */
  padding: 10px 10px; /* Adjust padding as needed */
  cursor: pointer;
  font-size: 14px; /* Adjust font size as needed */
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 60px;
  max-height: fit-content;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.two-btn button.active {
  background-color: #14d77c;
  color: white;
}

.two-btn button:hover {
  background-color: #14d77c;
  color: white;
  border-color: white;
}
}