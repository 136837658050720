.hospitalheader{
    background-color: #ffffff;
    max-width:100% ;
    height: 70px;
    width: auto;
    max-width: 100vw;
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
    z-index: 1;
    
}

.hospitalheader-container{
    text-align: center;
    color: #14D77C;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 100%;
    width: auto;
    max-width: 100vw;
    border-bottom: #14D77C 1px solid;
}

.hospitalheader-container h1{
    margin: 0px;
    font-size: 30px;
    padding-left: 20px;
}


.hospital-nav-menu-list{
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-evenly;
  gap: 60px;
  color: black;
  font-size: medium;
  padding: 0px;
}

.hospital-nav-menu-list a{
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  font-size: medium;
}

.hospital-nav-menu-list a:hover{
  color: #14D77C;

}

.hospitalheader-mobilecontainer{
    display: none;
  }

  /* Add this CSS to your stylesheet */
.spinner {
  border: 8px solid #14d77c; /* Light grey */
  border-top: 8px solid white; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add this CSS to your stylesheet */
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}


  @media screen and (max-width: 600px){
    .hospitalheader{
        width: 100vw;
    }

    .hospitalheader-container{
        width: 100vw;
        max-width: 600px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .hospital-nav-menu-list{
       display: none;
    }


    .hospitalheader-mobilecontainer{
        width: auto;
        display: flex;
        max-width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        height: 8vh;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        background-color: #ffffff;
        border: #14D77C 1px solid;
        font-size: small;
        z-index: 1;
    }

    .hospitalheader-mobile-home{
        width: 49vw;
        height: 8vh;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .hospitalheader-mobile-dashboard{
        width: 49vw;
        height: 8vh;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .hospitalheader-mobilecontainer a{
        text-decoration: none;
      }

      .active-link > div {
        color: #ffffff; /* Change the text color */
        background-color: #14D77C; /* Change the background color */
      }

}