.login-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f2;
}

.health-eagle-ls {
  width: 60vw;
  height: 100vh;
  background-color: #ffffff;
}

.health-eagle-text {
  color: #14D77C;
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 49.98px;
  word-wrap: break-word;
  text-align: center;
  padding-top: 30px;
}

.first-step {
  color: black;
  font-size: 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 70px;
  margin-top: 100px;
  padding-left: 70px;
  word-wrap: break-word;
  padding: 40px;
  text-align: start;
}

.login-form{
  width: 40vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #14D77C;
}


.form-background{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.form-titlel {
  width: 350px;
  display: flex;
  text-align: center;
  color: white;
  font-size: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 48.84px;
  word-wrap: break-word;
  flex-wrap: wrap;
  margin: 20px;
  align-content: center;
  align-items: center;
  height: 500px;
  flex-direction: column;
  justify-content: space-around;
}

.form-title h1{
  margin-top: 20px;
}

.form-input1{
  width: 300px;
  height: 44px;
  font-size: 22px;
  text-align: left;
  padding-left: 5px;
  border-radius: 15px;
  border: 1px black solid;
}

.form-input1::placeholder{
  color: rgba(0, 0, 0, 0.35);
  text-align: start;
  font-size: medium;
}


.form-input1 input[type="text"] {
  padding-left: 10px;
}



.login-button {
  width: 120px;
  height: 50px;
  text-align: center;
  background: white;
  color: #14D77C;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  line-height: 32.56px;
  word-wrap: break-word;
  border-radius: 15px;
}

.signup-link {
  width: 241px;
  height: 29px;
  text-align: center;
  color: white;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  line-height: 36.54px;
  text-decoration: underline;
  word-wrap: break-word;
  margin-top: 10px;
}


 
 /* For screens smaller than 600px, make the font size smaller */
  @media (max-width: 600px) {
    .header {
      font-size: 18px;
    }
  }
  
  /* For screens larger than 600px, make the font size larger */
  @media (min-width: 601px) {
    .header {
      font-size: 24px;
    }
  }


  /* For screens smaller than 600px, center the text and make the font size smaller */
@media (max-width: 600px) {

}

  @media (max-width: 600px) {
    .login-container {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      width: auto;
      height: 100vh;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      align-items: center;

    }

    .health-eagle-ls {
      width: 100vw;
      height: 20vh;
      background-color: transparent;
    }

    .health-eagle-text {
      font-size: 40px;
    
    }

    .first-step {
      font-size: 20px;
      max-width: 100vw;
      width: auto;
      height: 50px;
      margin-top: 0px;
      padding: 0px;
      text-align: center;
      display: none;
    }

    .login-form {
      background-color: transparent;
      width: 100vw;
      height: 80vh;
      display: flex;
      flex-direction: column;
      position: inherit;
      justify-content: flex-start;

    }

    .form-background{
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      background-color: #ffffff;
      border: #14D77C solid 3px;
      max-width: 500px;
      width: 310px;
      border-radius: 15px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    }

    .form-titlel {
      width: 100vw;
      height: 60vh;
      color: #14D77C;

    }

    .form-titlel h1{
      margin-top: 10px;
      font-size: 40px;
    }

    .form-input1 {
      width: 60vw;
    }

    .forgot-password-link{
      font-size: medium;
    }

    .login-button {
      width: 25vw;
      text-transform: uppercase;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #ffffff ;
      background-color: #14D77C;
    
    }

    .signup-link {
      width: 80vw;
      color: #14D77C ;
    }

    .error-message {
      font-size: medium;
      color: red;
    }
  }



















