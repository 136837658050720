.dashboard {
  font-family: Arial, sans-serif;
  display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

h1 {
  text-align: center;
  color: #333;
}

.add-patient-section {
  margin-bottom: 20px;
}

.refresh-btn button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.refresh-btn button:hover {
  background-color: #45a049;
}

.add-patient-btn button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-patient-btn button:hover {
  background-color: #45a049;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.add-patient-form input,
.add-patient-form select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.add-patient-form button {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-patient-form button:hover {
  background-color: #45a049;
}

.success-message-section {
  margin-bottom: 20px;
}

.success-message {
  color: green;
  font-weight: bold;
}

.selection-section{
  background-color: white;
  background-color: white;
    padding: 10px;
    width: 321px;
    border: #111111 0.1px solid;
    border-radius: 5px;
    margin-bottom: 20px;
    font-weight: 600;
}

.doctor-selection-section,
.slot-selection-section {
  margin-bottom: 20px;
}

.doctor-selection-section label,
.slot-selection-section label {
  display: block;
  margin-bottom: 8px;
}

.doctor-selection-section select,
.slot-selection-section select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.appointments-section {
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  width: 600px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.appointments-section h2 {
  color: #333;
}

.appointments-section ul {
  list-style-type: none;
  padding: 0;
}

.appointments-inner-section{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;

}


.appointment-sheet{
  border-radius: 5px;
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
    width: 340px;
    flex-direction: row;
    background-color: mintcream;
    align-items: baseline;
  
}


.each-appointment-sheet {
  border-radius: 5px;
    /* border-style: solid; */
    display: flex;
    justify-content: space-around;
    margin-bottom: 5px;
    width: 600px;
    flex-direction: row;
    background-color: lightgray;
    align-items: baseline;
}


.each-appointment-sheet:hover {
  background-color: #14d77c;
  color: #ffffff;
  .patient-token{
    background-color: #f10404;
    color: #ffffff;
  }
}

  
  .each-appointment-sheet li{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 80px;
    width: 600px;
  }



.patient-info{
  width: 376px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 72px;
}



.patient-token {
  /* height: 100%; */
  text-align: center;
  color: #ffffff;
  /* font-size: 15px; */
  /* font-family: 'Poppins'; */
  font-weight: 500;
  line-height: 21.83px;
  word-wrap: break-word;
  /* border-style: solid; */
  width: 24px;
  background-color: #14d77c;
  position: relative;
  right: 25px;
}

.patient-name {
  width: 105px;
  /* height: 37px; */
  /* text-align: center; */
  /* color: rgb(0 0 0 / 98%); */
  font-size: 16px;
  font-family: 'Poppins';
  font-weight: 800;
  line-height: 21.83px;
  word-wrap: break-word;
  /*border-style: solid;*/
  margin-right: 20px;
  /* margin-top: -30px;*/
}


.patient-contact {
  width: 102px;
  /* height: 100%; */
  /* text-align: center; */
  /* color: black; */
  /* font-size: 14px; */
  font-family: 'Poppins';
  font-weight: 300;
  /* line-height: 11.64px; */
  /* word-wrap: break-word; */
  /*border-style: solid;*/
  margin-right: 68px;
  /* margin-top: -30px; */
  /* margin-bottom: 9px; */
}


.patient-age {
  /* width: 11%; */
  /* height: 100%; */
  /* text-align: center; */
  /* color: rgba(0, 0, 0, 0.75); */
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 300;
  line-height: 14.55px;
  word-wrap: break-word;
}





  .patient-age-gender {
      /*border-style: solid;*/
      /* display: flex; */
      /* flex-direction: row; */
      /* justify-content: space-around; */
      /* align-items: center; */
      /* align-content: center; */
      /* flex-wrap: wrap; */
      width: 53px;
      /* margin-left: 177px; */
  }

.ticked {
background-color: #14D77C;
}



input[type="checkbox"] {
  appearance: none;
  background-color: white;
  border: 1px solid #080808;
  width: 35px;
  height: 36px;
  border-radius: 7px;
  position: relative;
  outline: none;
  /* margin-left: 298px; */
  margin-bottom: -18px;
  bottom: 15px;
  right: 10px;
}


input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 15px;  /* Increase the width */
  height: 15px;  /* Increase the height */
  background-color: #ccc;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

input[type="checkbox"]:checked:before {
  content: "✔";
  color: #1aed24;
  opacity: 1;
  width: 20px;
  height: 30px;
  background-color: #ffffff;
  font-size: 25px;
}

/* Add this CSS to your stylesheet */
.spinner {
  border: 8px solid #14d77c; /* Light grey */
  border-top: 8px solid white; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Add this CSS to your stylesheet */
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}


@media (max-width: 600px) {

  .appointments-section{
    border: #111111 0.1px solid;
    border-radius: 5px;
    width: 330px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 5px;
    margin-bottom: 40px;
  }

  .dashboard{
    margin-bottom: 50px;
  }

  .each-appointment-sheet {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border: #111111 0.1px solid;
    margin-bottom: 11px;
    width: 320px;
    height: 100px;
    flex-direction: row;
    align-items: center;
    background-color: mintcream;
}

.each-appointment-sheet li{
  display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        align-content: space-between;
        flex-wrap: wrap;
        height: 50px;

}

.patient-info{
  width: 204px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-left: 20px;
}
  
  
  .patient-token {
    /* height: 100%; */
    text-align: center;
    color: #FF0000;
    border: #f10404 0.1px solid;
    /* font-size: 15px; */
    /* font-family: 'Poppins'; */
    font-weight: 600;
    line-height: 21.83px;
    word-wrap: break-word;
    /* border-style: solid; */
    width: 24px;
    background-color: #ffffffff;
    /* margin-top: 16px; */

  }
  
  .patient-name {
    width: 105px;
    /* height: 37px; */
    /* text-align: center; */
    color: rgb(0 0 0 / 98%);
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 800;
    line-height: 21.83px;
    word-wrap: break-word;

  }
  
  
  .patient-age-gender {
    position: relative;
    /* border-style: solid; */
    width: 53px;

  }
  
  .patient-contact {
    width: 112px;
    color: black;
    font-family: 'Poppins';
    font-weight: 300;
    /* word-wrap: break-word; */
    
  }
  
  input[type="checkbox"] {
    appearance: none;
    background-color: white;
    border: 1px solid #080808;
    width: 35px;
    height: 36px;
    border-radius: 7px;
    position: relative;
    outline: none;
  }
}