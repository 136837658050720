.main{
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: 40px;
  width: auto;
  max-width: 100vw;
}

.main-container{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: auto;
  max-width: 100vw;

}

.banner-medicalstaff{
  margin-top: 70px;
  max-width: 100vw;
  width: auto;
  height: 40px;
  background-color: rgb(163, 255, 173);
  text-align: center;
  font-size: large;
  cursor: pointer;

}

.banner-medicalstaff h5{
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.curation{
  width: 48vw;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 20px;
}

.welcome-message{
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 20px;
}


.welcome-to{
  color: black;
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 1.75px;
  word-wrap: break-word;
  margin: 0px;
}

.health-eagle{
  color: #14D77C;
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 1.75px;
  word-wrap: break-word;
  margin: 0px;
}

.healthcare-message{
  font-size: large;
  font-weight: bold;
  text-align: center;
  word-spacing: 5px;
  letter-spacing: 5px;
  margin-top: 5px;
  margin-bottom: 50px;
}


.browser-compatibility-message {
  color: red;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.care-guide-message{
  margin-top: 100px;
}
.care-guide-message p{
  font-size: xx-large;
  font-weight: 500;
  padding-left: 0px;
  margin: 0px;
  max-width: 550px;
  width: auto;
  padding-left: 100px;
}

.home-illustration{
  display: flex;
  justify-content: center;
  width: 48vw;
  margin-left: 20px;

}

.home-illustration img{
  max-width: 50vw;
  width: auto;
  height: 550px;
}


.appointment-message{
  font-size: xx-large;
  font-weight: 700;
  text-align: center;
  margin-top: 50px;
  word-spacing: 8px;
  width: 800px;
}

.Hospitals-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 100vw;
  margin-top: 50px;
  margin-bottom: 100px;
}

.install-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: black 0.5px solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

.install-message p {
  margin: 0;
  margin-right: 10px;
}

.install-msg-btn button {
  background-color: #45a049;
  color: #fff;
  border: none;
  padding: 12px 19px;
  border-radius: 5px;
  cursor: pointer;
}

.install-msg-btn button:hover {
  background-color: #45a049;
}

.install-message.clos{
  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.close-msg-button:hover {
  color: #ff0000;
}

  /* For screens smaller than 600px, make the font size smaller */
  @media (max-width: 600px) {
    .header {
      font-size: 18px;
    }
  }
  
  /* For screens larger than 600px, make the font size larger */
  @media (min-width: 601px) {
    .header {
      font-size: 24px;
    }
  }


  /* For screens smaller than 600px, center the text and make the font size smaller */
@media (max-width: 600px)  {
    #html, #body ,#root {
      background-color: #f3f4f2;
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: auto;
      max-width: 100vw;
    }
  
  .main {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    gap: 15px;
    width: auto;
    max-width: 100vw;
  
  }


  .banner-medicalstaff {
    display: flex;
    height: 50px;/* Optional: Add space between buttons */
    margin-top: 0px;
    align-items: center;
    background-color: #f3f4f2;
  }
  .button-link {
    display: inline-block;
    padding: 5px 30px;
    margin: 0px 3px;
    font-size: 16px;
    background-color: #14d77c;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;

  }
  
  .button-link:hover {
    background-color: #4caf50; /* Darker green background on hover */
  }



  .main-container {
    padding: 10px 10px;
    background-color: #f9f9f9;
    text-align: center;
  }
.welcome-message {
  margin-bottom: 20px;
}

.welcome-to {
  font-size: 24px;
  color: #333;
}

.healthcare-message {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
  margin-bottom: 5px;
}

.health-eagle {
  font-size: 24px;
  color: #14d77c;
}

.care-guide-message{
  padding: 0px;
  font-size: medium;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 30px;
}

.care-guide-message p{
  padding: 0px;
  font-size: medium;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}


.curation{
  width: auto;
  max-width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
}


.home-illustration{
  display: none;
}

.medical-staff-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 100vw;
  margin-top: 10px;
  background-image: url(./Assets/doctor.jpg);
  background-size: cover;
  background-position: center;
  padding: 20px;
  border-radius: 10px;
  color: white; /* Ensure text is readable */
  text-shadow: 1px 1px 2px black; /* Add text shadow for better readability */
}

.medical-staff-container h2{
  font-size: 20px;
  color: #fff5f5;
  margin-top: 102px;
}

.medical-staff-container p{
  font-size: 14px;
  color: #fff5f5;
  margin: 0px;
}


.Hospitals-container{
  width: auto;
  max-width: 100vw;
  margin: 0px;
}
.appointment-message{
  padding-right: 35px;
  width: auto;
  max-width: 100vw;
  font-size: 12px;
  font-weight: 600;
  margin-top: 25px;
  padding-left: 20px;
}


.install-message {
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
}

.install-message p {
  margin: 0;
  margin-right: 10px;
}


  .install-msg-btn {
    /* padding: 8px; */
    /* width: 80px; */
    /* height: 25px; */
    margin-top: 20px;
    /* text-align: center; */
    font-weight: 400;
}

.install-msg-btn:hover {
  background-color: #45a049;
}
  

  .install-message {
    width: 70%;
    height: 20%;
    padding: 10px;
  }

  .install-message p {
    margin-right: 5px;
    margin-top: 25px;
    font-size: 16px;
    font-weight: 600;
  }

    .install-msg-btn {
      /* padding: 8px; */
      /* width: 80px; */
      /* height: 25px; */
      margin-top: 20px;
      /* text-align: center; */
      font-weight: 400;
  }

  .close-msg-button{
    font-size: 14px;
    position: relative;
    left: 110px;
    bottom: 140px;
    border: black 0.5px solid;
  }

  /* Existing styles */

/* Services Section */
/* Services.css */
.services-section {
  padding: 20px;
  background-color: #f9f9f9;
}


.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tabs button {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f0f0f0;
  cursor: pointer;
  transition: background 0.3s;
  font-size: large;
}

.tabs button.active {
  background: #14d77c;
  color: white;
}

.services-list {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
}

.service-item {
  margin-bottom: 20px;
}


/* Footer Section */
.footer-section {
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}


/* Contact Section */
.contact-section {
  margin: 0px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-section h2 {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.contact-section p {
  font-size: 16px;
  color: #333;
  margin: 5px 0;
}

.contact-section a {
  color: #14d77c;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}

}










/*css for menu button*/
/* .main-container {
  width: 100%;
  height: 100%;
  position: relative;
  
}

.circle {
  width: 45px;
  height: 45px;
  position: absolute;
  background: #14D77C;
  border-radius: 9999px;
  top: 60px;
  right: 285px;
  left: 298px;
}

.inner-div {
  width: 23px;
  height: 13px;
  left: 9px;
  top: 13px;
  position: absolute;
}

.inner-div div {
  width: 23px;
  height: 0;
  position: absolute;
  border: 3px white solid;
}

.inner-div div:nth-child(1) {
  top: 0;
}

.inner-div div:nth-child(2) {
  top: 13px;
}

.inner-div div:nth-child(3) {
  top: 6.84px;
}
 */























