.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
  }

  .patient-booking{
    width: 500px;
    height: 350px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    border-radius: 15px;
    align-items: center;
}

.booking-form-container{
    width: 600px;
    height: 650px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 50px;
    padding-top: 40px;
    border-radius: 15px;
    justify-content: flex-start;
    align-items: center;
    align-content: center

}

.payment-container{
  display: none;
}


.no-scroll {
  overflow: hidden;
}
@media screen and (max-width: 600px){

    .patient-booking{
        max-width: 300px;
        width: auto;
        height: fit-content;
    }


    .booking-form-container{
        max-width: 300px;
        width: auto;
        background-color: aliceblue;
        border-radius: 15px;
        height: 450px;
    }

    .booking-form-container form{
        max-width: 300px;
    }

    
  .select-gender-box {
    padding: 7px;
    width: 100px;
    margin-left: 34px;
    margin-top: 7px;
  }

  .select-gender-text{
    margin-left: 42px;
  }

  .book-appointment-buttn{
    font-size: 16px;
    color: #14D77C;
    width: 255px;
    padding: 9px;
    margin: 37px;
  }
}


