
.hospital-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 40px;
  flex-wrap: wrap;
  max-width: 1200px;
  width: 100vw;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-content: center;
  align-items: center;
}

.hospital-list input {
  width: 75%;
  padding: 20px;
  margin: 20px;
  border-radius: 61px;
  border: rgba(0, 0, 0, 0.25) solid;
}


.hospital-list input::placeholder {
  color: rgb(0, 0, 0, 0.5);
  font-size: medium;
  font-weight: 600;
}

.hospital-box{
  max-width: 900px;
  width: auto;
  display: flex;
  max-height: 240px;
  min-height: 100px;
  height: auto;
  background-color: #d9d9d9;
  border-radius: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

.hospital-box:hover {
  background-color: rgb(163, 255, 173);
  color: #14D77C;
  border: #14D77C 1px solid;

}

.hospital-name{
  color: #000000;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  width:400px;
  margin:0px;
  padding: 10px;
  text-transform: uppercase;
}


.address-container {
  width: 400px;
  height: 100%;
  text-align: start;
  padding: 10px;
  font-size: 18px;
  text-transform: uppercase;
}

.address-label {
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
}

.address-details{
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase
}

.doctor-info-p {
  padding: 15px;
  font-size: 15px;
  background-color: #14D77C;
  border-style: outset;
  color: white;
  font-weight: 700;
  text-transform: uppercase

}




.viewmore-button {
  background-color: #ffffff; /* Green */
  border: none;
  color:  #14d77c;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-size: larger;
  font-weight: 600;
}


  /* For screens smaller than 600px, make the font size smaller */
  @media (max-width: 600px) {
    .header {
      font-size: 18px;
    }
  }
  
  /* For screens larger than 600px, make the font size larger */
  @media (min-width: 601px) {
    .header {
      font-size: 24px;
    }
  }


  /* For screens smaller than 600px, center the text and make the font size smaller */
@media (max-width: 600px) {

  .hospital-list{
    width: auto;
    max-width: 95vw;
    margin-bottom: 20px;
    margin-top: 30px;

  }
  .hospital-box {
    max-width: 500px;
    width: auto;
    height: 100%;
    max-height: 330px;
    min-height: 150px;
    background: #f5f5f5;
    border-radius: 24px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.hospital-box:hover {
  border: #14D77C 1px solid;
  background: #f5f5f5;
}

.doctor-info{
  display: flex;
  flex-direction: row;
  gap: 5px;
} 

.doctor-info-p {
  padding: 15px;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #14D77C;
  border-style: none;
  color: white;
  font-weight: 700;
  width: 291px;
}





.doctor-info-p-availability{
  font-size: 12px;
}

.doctor-info-p-timings{
  font-size: 12px;
}

  .hospital-list input {
    padding: 10px;
    margin: 20px;
    border-radius: 50px;
    border: rgba(0, 0, 0, 0.25) solid;
    width: auto;
    min-width: 250px;
}

.hospital-list input::placeholder {
  color: rgb(0, 0, 0, 0.5);
  font-size: small;
  font-weight: 600;
}

  
  .address-container {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
  }
  
  .address-label {
    /* color: white; */
    color: black;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 14.28px;
    letter-spacing: 0px;
    word-wrap: break-word;
  }
  
  .address-details {
        /* color: white; */
        color: black;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: lighter;
        line-height: 14.28px;
        /* letter-spacing: 0.50px; */
        word-wrap: break-word;
  }

  .hospital-name {
    width: 100%;
    height: 100%;
    text-align: center;
    color: #14D77C;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 0px;
    word-wrap: break-word;
    margin-top: 15px;
  }


}