.firstappointment-page-appointment-container {
  max-width: 100vw;
  width: auto;
  /* width: 100vw; */
  height: 100vh;
  height: auto;
  }

  .xyz{
  position: relative;
    top: 67px;
  }
  
  .firstappointment-page-appointment-box {
    display: flex;
    width: 900px;
    height: 100%;
    /* left: 0; */
    /* top: 0; */
    /* position: relative; */
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: 0.50px black solid;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .firstappointment-page-hospital-name {
    width: 800px;
    height: 30px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: black;
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 700;
    line-height: 29.10px;
    word-wrap: break-word;
  }
  
  .firstappointment-page-doctor-name {
    width: 500px;
    height: 45px;
   
    text-align: center;
    color: black;
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 21.83px;
    word-wrap: break-word;
  }
  
  .firstappointment-page-line1,
  .firstappointment-page-line2 {
    width:800px;
    height: 0;

    border: 1px black solid;
  }
  
  .firstappointment-page-line2 {
    bottom: 0px;
  }

  .firstappointment-page-line1 {
    bottom: 0px;
  }

  
  
  .firstappointment-page-patient-name {

    height: 39px;

    text-align: center;
    color: #14C77D;
    font-size: 35px;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 29.10px;
    word-wrap: break-word;
  }
  
  .firstappointment-page-your-token,
  .firstappointment-page-current-token {
    width: 700px;
    height: 150px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: black;
    font-size: 30px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 26.19px;
    word-wrap: break-word;
    top: 164px;
    justify-content: space-between;
    align-items: center;
    margin: 20px;  }
  
  
  .firstappointment-page-token-box1{
    display: flex;
    width: 150px;
    height: 150px;
    background: #D9D9D9;
    border-radius: 15px;
    border: 0.20px black solid;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  
  .firstappointment-page-token-box2 {
    display: flex;
    width: 150px;
    height: 150px;
    background: #D9D9D9;
    border-radius: 15px;
    border: 0.20px black solid;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
  }
  
  .firstappointment-page-token-box2 {
    top: 0px;
  }
  
  .firstappointment-page-queue-number,
  .firstappointment-page-current-queue-number {
    /* text-align: center; */
    padding-top: 30px;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 90px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 71.30px;
    word-wrap: break-word;
  }
  
  .firstappointment-page-queue-number {

    color: #14D77C;
  }
  
  .firstappointment-page-current-queue-number {

    color: #FF0000;
    padding-top: 10px;
  }
  
  .firstappointment-page-live-box {
    display: flex;
    width: 50px;
    height: 15px;
    background: #D9D9D9;
    border-radius: 15px;
    border: 0.50px #FF0000 solid;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  @keyframes blink {
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}
  
  .firstappointment-page-live-text {
    padding-top: 6px;
    width: 34px;
    height: 16px;
    text-align: center;
    color: #FF0000;
    font-size: 9px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0.35px;
    word-wrap: break-word;
    animation: blink 1s linear infinite;
  }
  
  .firstappointment-page-live-dot {
    width: 3px;
    height: 3px;

    background: #FF0000;
    border-radius: 9999px;
  }


  @media screen and (max-width: 600px){
    .firstappointment-page-appointment-container{
      max-width: 100vw;
      width: auto;
    }

    .firstappointment-page-appointment-box{
      max-width: 90vw;
      width: auto;
      gap: 5px;
    }

    .firstappointment-page-hospital-name{
      max-width: 70vw;
      width: auto;
      font-size: larger
    }
    
    .firstappointment-page-doctor-name{
      max-width: 70vw;
      width: auto;
      font-size: 20px;
      height: 20px;
      margin-bottom: 28px;
      position: relative;
      top: 17px;
    }
    .firstappointment-page-patient-name{
      max-width: 70vw;
      width: auto;
      margin-top: 22px;
      font-size: 25px;
    }
    .firstappointment-page-your-token,
    .firstappointment-page-current-token{
     
      max-width: 80vw;
      width: 80vw;
      font-size: 14px;
      font-weight: 600;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0px;
      align-items: center;
    }
    .firstappointment-page-queue-number,
    .firstappointment-page-current-queue-number{
      max-width: 60px;
      max-height: 50px;
      font-size: xxx-large;
      font-weight: 600;
      padding-top: 0px;
      line-height: 50px;
    }
    .firstappointment-page-live-box{
      width: 44px;
      height: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: baseline;
    }
    .firstappointment-page-live-text{
      font-size: 8px;
    }
    .firstappointment-page-live-dot{
      width: 3px;
      height: 3px;
    }
    .firstappointment-page-token-box1{
      width: 80px;
      height: 80px;
    }
    .firstappointment-page-token-box2{
      width: 80px;
      display: flex;
      height: 80px;
      flex-direction: column;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
    }

    .firstappointment-page-line1,
    .firstappointment-page-line2{
      max-width: 400px;
      min-width: 300px;
      width: auto;
    }
  }