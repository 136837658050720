.user-hospitallist{
    margin-top: 100px;
    max-width: 100vw;
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    margin-bottom: 80px;
}

.hospital-doctors{
    max-width: 100vw;
    width: 1000px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    align-items: center;
}

.doctor-info{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 15px
}

.slot-alert{
    font-size: xx-large;
    color: black;
}

.doctor-info-p{
    font-size: 15px;
    /* display: flex; */
    width: -moz-fit-content;
    width: fit-content;
    min-width: 250px;
    height: 50px;
    background-color: #ffffff;
    border-style: solid;
    color: #000000;
    font-weight: 700;
    border-radius: 15px;
    border: #000000 solid;
    text-transform: uppercase;
}

.doctor-info-p-availability{
    border: #14D77C solid 1px;
    border-radius: 10px;
    text-align: center;
    color: #14D77C;
    background-color: #ffffff;
    font-size: 7px;
    width: 35px;
    height: 5px;
    padding: 5px;
    position: relative;
    bottom: 57px;
    left: 180px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
}




.user-hospital-box{
    padding-bottom: 25px;
    max-width: 900px;
    width: auto;
    display: flex;
    max-height: fit-content;
    min-height: 100px;
    height: auto;
    background-color: #d9d9d9;
    border-radius: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.user-hospital-box:active,
.user-hospital-box:hover {
    background-color: rgb(163, 255, 173);
    color: #14D77C;
    border: #14D77C 1px solid;
  }

.slots-buttons{
    display: flex;
    flex-direction: row;
    height: auto;
    max-height: fit-content;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    gap: 10px;
    align-items: stretch;

}

.slots-buttons button{
    border: #14d77c 2px solid;
    font-size: 15px;
    /* word-spacing: 0px; */
    padding: 10px;
    text-transform: uppercase;
}

.view-more{
    background-color: #ffffff; /* Green */
    border: none;
    color:  #14d77c;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    font-size: larger;
    font-weight: 600;
}


/* HospitalListForUsers.css */
.slots-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
  
  .slots-buttons button {
    background-color: white; /* Same as .Profile-button background color */
    color: black;
    border: 3px solid #14d77c;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  
@media screen and (max-width: 600px){

    .hospital-list{
        text-align: center;
    }

    .hospital-list h3{
        font-size: 20px;
        margin:5px;
        margin-top: 15px;
    }

    

.user-hospital-box{
    max-width: 340px;
    width: auto;
    height: fit-content;
    /* min-height: 150px; */
    background: #f5f5f5;
    border-radius: 24px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-bottom: 25px;
}

.slots-buttons{
    display: flex;
}

.hospital-doctors{
    display: flex;
    height: fit-content;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    /* gap: 0px; */
    align-items: center;
    justify-content: space-evenly;
}

}