.hospital-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
}

.hospital-item button {
    background: none;
    border: none;
    text-align: left;
    width: 100%;
}

.doctors-list {
    margin-top: 10px;
    padding-left: 20px;
}