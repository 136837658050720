
.active-appointments{
    max-width: 100vw;
    width: auto;
    margin-top: 100px;
    display: flex;
    justify-content: center;
}

.current-token-number{
    color: red;
    font-size: 18px;
    height: 40px;
    margin: 5px;
    font-weight: 'bolder';
    display: contents;
}

.Assigned-token-number{
    color:#14c77d;
    font-size: 18px;
    height: 40px;
    font-weight: 'bolder';
}




.appointment-container {
    top: 100px;
    display: flex;
    position: relative;
    font-family: 'Poppins', sans-serif;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    margin-bottom: 200px;
}

.appointment-container h3 {
    margin: 0px;
}

.appointment-box {
    display: flex;
    width: 500px;
    height: 550px;
    color: black;
    font-size: 18px;
    line-height: 22px;
    word-spacing: 5px;
    font-weight: 600;
    word-wrap: break-word;
    margin-top: 0px;
    border-radius: 15px;
    background-color: #ffffff;
    border-style: solid;
    border-color: #14c77d;
    padding: 23px;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.appointment-box p{
    margin-left: 80px;
}
.appnt-dic{
    margin: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}


.cancel-button {
    width: 300px;
    height: 50px;
    position: absolute;
    left: 115px;
    top: 520px;
    /* bottom: -20px; */
    border: red solid 1px;
    background:white;
    color:  rgb(255 0 0);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.05px;
    text-align: center;
    line-height: 40px;
}

.A-tokken-number{
    width: 40px;
    height: 30px;
    position: relative;
    margin: 0px;
    color: #14d77c;
    padding-top: 10px;
    font-size: 22px;
    font-weight: 900;
    text-align: center;
    border-radius: 10px;
    left: 240px;
    background-color: #d9d9d9;
    bottom: 29px;
}

.C-tokken-number{
    width: 40px;
    height: 30px;
    position: relative;
    margin: 0px;
    color: red;
    padding-top: 10px;
    font-size: 22px;
    font-weight: 900;
    text-align: center;
    border-radius: 10px;
    left: 240px;
    background-color: #d9d9d9;
    bottom: 29px;
}

@media screen and (max-width: 600px){
    .active-appointments{
        max-width: 100vw;
        width: auto;
        margin-top: 100px;
        display: flex;
        justify-content: center;
    }
    
    .current-token-number{
        color: red;
        font-size: 18px;
        height: 40px;
        margin: 0px;
        font-weight: 'bold';
    }

    .Assigned-token-number{
        color: #14c77d;
        font-size: 18px;
        height: 40px;
        font-weight: 'bold';
        margin-top: 15px;
    }
    
    
    
    
    .appointment-container {
        position: relative;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 100px;
        z-index: 0;
        bottom: -0;
        top: 0px;
        margin-top: 100px;
    }
    
    /* .appointment-container h1 {
        height: 37px;
        text-align: center;
        
        position: fixed;
       
        color: black;
        font-size: 30px;
        font-weight: 600;
        word-wrap: break-word;
        bottom: 00px;
        top: 20px;
        margin-right: 0px;
        margin-left: 0px;
        right: 00px;
        left: 00px;
        margin-top: 0px;
        margin-bottom: 0px;
        width: 260px;
    } */
    
    .appointment-box {
        width: 280px;
        height: 500px;
        color: black;
        font-size: 17px;
        line-height: 22px;
        word-spacing: 5px;
        font-weight: 600;
        word-wrap: break-word;
        border-radius: 15px;
        background-color: #ffffff;
        border-style: solid;
        border-color: #14c77d;
        padding: 23px;
        position: relative;
        display: flex;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap:6px;
        }

        @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
          
          .blink {
            animation: blink 1s infinite;
            margin: 0px;
            color: red;
        font-size: x-large;
        font-weight: bolder;
          }

          .plink {
            margin: 0px;
        font-size: x-large;
        font-weight: bolder;
          }

       
    
    .cancel-button {
        width: 235px;
        height: 40px;
        position: absolute;
        left: 55px;
        top: 480px;
        border: red solid 2px;
    background:white;
    color:  rgb(255 0 0);
        font-size: 15px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1.05px;
        text-align: center;
        line-height: 40px;
    }

    .A-tokken-number{
        width: 40px;
        height: 30px;
        position: relative;
        margin: 0px;
        color: #14d77c;
        padding-top: 10px;
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        border-radius: 10px;
        left: 240px;
        background-color: #d9d9d9;
        bottom: 29px;
    }

    .C-tokken-number{
        width: 40px;
        height: 36px;
        position: relative;
        margin: 0px;
        color: red;
        padding-top: 4px;
        font-size: 22px;
        font-weight: 900;
        text-align: center;
        border-radius: 10px;
        left: 240px;
        background-color: #d9d9d9;
        bottom: 29px;
    }

    .appointment-box p{
        margin: 12px;
    }

    }
    
