.header {
    background-color: #ffffff;
    max-width:100% ;
    height: 70px;
    width: auto;
    max-width: 100vw;
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;

  }

  .header-container{
    text-align: center;
    color: #14D77C;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    height: 100%;
    width: auto;
    max-width: 100vw;
    border-bottom: #14D77C 1px solid;
  }


  .header-container h5{
    margin: 0px;
    font-size: 30px;
    padding-left: 20px;
  }

  .header-container img{
    height: 50px;
    padding-left: 20px;
  }


.nav-menu-list{
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  gap: 50px;
  color: black;
  font-size: medium;
  padding: 0px;
}

.li{
  cursor: pointer;

}

.nav-menu-list li{
  cursor: pointer;
}

  .buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-right: 30px;

  }


  .signup, .login{
    background-color: #14D77C;
    color: white;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  }

  .login:hover{
    transform: scale(1.1);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .signup:hover{
    transform: scale(1.1);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .signup:active, .login:active {
    transform: translateY(4px);
  }
  
  /* For screens smaller than 600px, make the font size smaller */
  @media (max-width: 600px) {
    .header {
      font-size: 18px;
    }
  }
  
  /* For screens larger than 600px, make the font size larger */
  @media (min-width: 601px) {
    .header {
      font-size: 24px;
    }
  }


  /* For screens smaller than 600px, center the text and make the font size smaller */
@media (max-width: 600px) {
  .header {
    display: flex;
    justify-content: center;
    width: auto;
    max-width: 100vw;
    height: 60px;

  }

    .header-container {
      color: #14D77C;
      font-size: 20px;
      font-family: Poppins, sans-serif;
      font-weight: 700;
      word-wrap: break-word;
      margin: 11px;
      border-style: outset;
      background-color: #ffffff;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 1;
      position: fixed;
      top: 0px;
      border-width: 0px;
      width: auto;
      max-width: 100vw;
      height: 60px;
      justify-content: space-around;
      bottom: 682px;
      margin: 0px;
      right: 0px;
      left: 0px;
      padding-left: 0px;
      gap:0px;
      border-bottom: #14D77C 1px solid;
    }


    .header-container h5{
      padding-left: 0px;
      font-size: 20px;
      font-weight: 700;
      width: auto;
    }

    .header-nav-menu{
      display: none;
    }
         
    
          /* .buttons {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 20px;
          }
           */
           .buttons {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-right: 0px;
            margin-left: 0px;
            width: auto;
            height: 40px;
                     }


          .signup, .login {
            background-color: #14D77C;
            color: white;
            font-size: 15px;
            font-family: 'Poppins', sans-serif;
            font-weight: bolder;
            border: none;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
            width: 80px;
            height: 40px;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
        }
          
          .signup:hover, .login:hover {
            transform: scale(1.1); ;
          }
    }

  
  /* For screens larger than 600px, make the width 100% */
  /* @media (min-width: 601px) {
    .header {
         width: 100%; 
        height: 100%;
  
        color: #14D77C;
        font-size: 24px;
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        word-wrap: break-word;
        margin: 11px;
        border-style: outset;
  
    }
  } */