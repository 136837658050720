.navbar {
    background-color: #f8f9fa;
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.navbar a {
    text-decoration: none;
    color: black;
}