/* UserProfilePage.css */

body.no-scroll {
  overflow: hidden;
}

.profile-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: none;
}

.profile-blur.active {
  display: block;
}

.profile-container {
  position: fixed;
  top: 73px;
  right: 0%;
  width: 85%;
  height: 87%;
  background: #fff;
  z-index: 20;
  transition: right 0.3s ease;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow-y: auto;
}

.profile-container.active {
  right: 100%;
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-header p {
  margin: 0;
  font-size: 18px;
  color: #14d77c;
}

.profile-avatar {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust the height as needed */
  width: 100px;  /* Adjust the width as needed */
  border: 1px solid #ccc; /* Optional: Add a border for better visibility */
  border-radius: 50%; /* Optional: Make it circular */
  position: relative;
    left: 35%;
}

.profile-avatar .fas {
  font-size: 50px; /* Adjust the size as needed */
  color: #333; /* Optional: Change the color */
}


.profile-item {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  color: #000;
  text-align: center;
  text-decoration: none;
}

.profile-item a {
  text-decoration: none;
  margin: 0px;
  color: #000;
}

.profile-item:hover {
  background: #14d77c;
  color: #fff;
}

.logout-button {
  margin-top: 20px;
  padding: 10px;
  background: #14d77c;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

.logout-button:hover {
  background: #0fbf6a;
}

@media (max-width: 768px) {
  .profile-container {
    width: 85%;
    display: flex;
    width: 85%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-decoration: none;
  }
}