.slot-list {
  display: flex;
  flex-direction: column;
  gap: 25px; /* Space between each slot */
  padding: 10px;
}

.each-slot {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between elements inside each slot */
}

.each-slot h3 {
  margin: 0;
  font-size: 18px;
}

.each-slot p {
  margin: 5px 0;
  font-size: 16px;
}

.each-slot input[type="text"] {
  width: 80%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.each-slot button {
  background-color: #14d77c;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.each-slot button:hover {
  background-color: #14d77c;
}

.edit-del-btns{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10px;
  margin: 15px;
}

.switch-2 {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-2 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-2 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-2:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider-2 {
  background-color: #14d77c;
}

input:checked + .slider-2:before {
  transform: translateX(26px);
}

.switch-label-2 {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  vertical-align: middle;
}

.switch-label-2.on {
  color: #14d77c;
}

.switch-label-2.off {
  color: red;
}

.edit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
}



/* Media query for mobile devices */
@media (max-width: 600px) {
  .each-slot {
    padding: 10px;
    display: flex;
        flex-direction: column;
        align-items: center
  }

  .each-slot h3 {
    font-size: 16px;
  }

  .each-slot p {
    font-size: 14px;
  }

  .each-slot input[type="text"] {
    font-size: 14px;
    padding: 6px;
  }

  .each-slot button {
    font-size: 14px;
    padding: 8px;
    width: 100px;
  }
}