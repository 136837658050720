.request-box{
    border-style: solid;
    border-width: 1px;
    border-color: black;
    margin: 10px;
    padding: 10px;
}

.main-req-box{
    display: flex;
    flex-direction: column;
}

.main-req-box p{
    text-align: center;
}