/* TermsOfService.css */

.terms-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .terms-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-container h2 {
    margin-top: 20px;
  }
  
  .terms-container p {
    margin-bottom: 10px;
    line-height: 1.6;
  }