.userheader{
    background-color: #ffffff;
    max-width:100% ;
    height: 70px;
    width: auto;
    max-width: 100vw;
    position: fixed;
    right: 0px;
    left: 0px;
    top: 0px;
    z-index: 1;
    
}

.userheader-container{
    text-align: center;
    color: #14D77C;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    width: auto;
    max-width: 100vw;
    border-bottom: #14D77C 1px solid;
}

.userheader-container h1{
    margin: 0px;
    font-size: 30px;
    padding-left: 20px;
}


.user-nav-menu-list{
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: space-evenly;
  gap: 60px;
  color: black;
  font-size: medium;
  padding: 0px;
}

.user-nav-menu-list a{
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  font-size: medium;
}

.user-nav-menu-list a:hover{
  color: #14D77C;

}

.userheader-buttons {
    margin-right: 30px;

  }

  .userheader-mobilecontainer{
    display: none;
  }

  .Profile-button{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #14D77C;
  }

  


  @media screen and (max-width: 600px){
    .userheader{
        width: 100vw;
    }

    .userheader-container{
        width: 100vw;
        max-width: 600px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .user-nav-menu-list{
       display: none;
    }


    .userheader-mobilecontainer{
        width: auto;
        display: flex;
        max-width: 100vw;
        position: fixed;
        left: 0;
        bottom: 0;
        height: 8vh;
        justify-content: space-around;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        background-color: #ffffff;
        border: #14D77C 1px solid;
        font-size: small;
        z-index: 1;
    }

    .userheader-mobile-bookappointments{
      width: 42.25vw;
      height: 8vh;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .userheader-mobile-home{
        width: 14vw;
        height: 6vh;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        z-index: 2;
        border-radius: 50px;
    }

    .userheader-mobile-activeappointments{
      width: 42.25vw;
      height: 8vh;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
    }




      .userheader-buttons {
        margin-right: 10px;
    
      }

      .Profile-button{
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background-color: #14D77C;
      }

      .userheader-mobilecontainer a{
        text-decoration: none;
      }

      .active-link > div {
        color: #ffffff; /* Change the text color */
        background-color: #14D77C; /* Change the background color */
      }
  }